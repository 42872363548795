import React from 'react';
import style from './CustomAlert.module.scss'
import { Btn } from '../../c_ui/Btn';

export const CustomAlert = ({ message, onClose }) => {
  const handleCloseAlert = () => {
    onClose('')
  }

	return (
		<div className={style.backdrop}>
			<div className={style.container}>
				<p className={style.message}>{message}</p>

				<Btn text={'OK'} handle={handleCloseAlert} />
			</div>
		</div>
	)
}
