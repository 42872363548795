import React, { useContext, useState } from 'react'
import { Btn } from '../../c_ui/Btn'
import style from './AddOption.module.scss'
import axios from 'axios'
import { getTranslation, Language } from '../../language'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { UploadProgressModal } from '../../components/UploadProgressModal'
import { Input } from '../../c_ui/Input/Input'
import { CustomAlert } from '../../components/CustomAlert'
import autorizationAnalizer from '../../service/functions/autorizationAnalizer'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const AddOption = ({ userName }) => {
  const navigate = useNavigate()
  const lang = useContext(Language)
  const TEXT = getTranslation(lang)
	const [date, setDate] = useState('')
	const [resource, setResource] = useState('')
	const [files, setFiles] = useState([])
	const [comment, setComment] = useState('')
	const [progress, setProgress] = useState([])
	const [isLoader, setIsLoader] = useState(false)
  const [alertText, setAlertText] = useState('')

  const handleUploadFiles = async event => {
		event.preventDefault()
		
		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			navigate('/')
			return
		}

		if (!date.length) {
			setAlertText(TEXT.date_is_required)
			return
		} else if (!resource.length) {
			setAlertText(TEXT.resource_is_required)
			return
		} else if (!files.length) {
			setAlertText(TEXT.file_is_required)
			return
		} else {
			document.body.style.overflow = 'hidden'
	
			const initialProgress = files.map(file => ({
				fileName: file.name,
				progress: 0,
			}))
			setProgress(initialProgress)
			setIsLoader(true)
	
			for (const file of files) {
				await uploadFile(file)
			}
	
			document.body.style.overflow = ''
			navigate(0)
		}
	}
	
	const uploadFile = async file => {
		const formData = new FormData()
		formData.append('date', date)
		formData.append('resource', resource)
		formData.append('researcher_name', userName)
		formData.append('comment', comment)
		formData.append('file', file)

		try {
			await axios.post(`${BASE_URL}/uploadFile`, formData, {
				onUploadProgress: event => {
					const total = event.total // загальний розмір файлу
					const currentProgress = Math.round((event.loaded / total) * 100)

					setProgress(prevProgress =>
						prevProgress.map(p =>
							p.fileName === file.name // оновлюємо перший файл
								? { ...p, progress: currentProgress }
								: p
						)
					)
				},
			})
		} catch (error) {
			setIsLoader(false)
			console.error('Upload failed:', error)
		}
	}

	return (
		<div className={style.add}>
			<form onSubmit={handleUploadFiles} className={style.form}>
				<span className={style.row}>
					<p className={style.text}>{TEXT.researcher_add_date}</p>

					<Input inputType={'date'} inputValue={date} setInputValue={setDate} />
				</span>

				<span className={style.row}>
					<p className={style.text}>{TEXT.researcher_add_resource}</p>

					<Input
						inputType={'text'}
						inputPlaceholder={TEXT.researcher_add_resource_placeholder}
						inputValue={resource}
						setInputValue={setResource}
					/>
				</span>

				<span className={style.row}>
					<p className={style.text}>{TEXT.researcher_add_files}</p>

					<Input inputType={'files'} setInputValue={setFiles} />
				</span>

				<span className={style.row}>
					<p className={style.text}>{TEXT.researcher_add_comment}</p>

					<textarea
						value={comment}
						placeholder={TEXT.researcher_add_comment_placeholder}
						onChange={e => setComment(e.target.value)}
						className={classNames({
							[style.input]: true,
						})}
					/>
				</span>

				<Btn type='submit' text={TEXT.researcher_btn_upload} />
			</form>

			{isLoader && <UploadProgressModal progress={progress} />}
			{alertText.length > 0 && <CustomAlert message={alertText} onClose={setAlertText} />}
		</div>
	)
}
