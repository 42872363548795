import React, { useContext, useState } from 'react'
import style from './Input.module.scss'
import { getTranslation, Language } from '../../language'

export const Input = ({
	inputType,
	inputPlaceholder = '',
	inputValue,
	selectedValue,
	setInputValue,
	minValue,
	maxValue,
	optionalHadle = () => {},
}) => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const maxDate = new Date()
		.toLocaleString()
		.slice(0, 10)
		.split('.')
		.reverse()
		.join('-')
	const [filesData, setFilesData] = useState([])

	const handleCheckNumberAndUpdate = event => {
		const regExp = /^\d*$/
		const value = event.target.value

		if (regExp.test(value)) {
			setInputValue(value)
		}
	}

	const handleCheckStringAndUpdate = event => {
		const regExp = /^[A-Za-z0-9_!@№#%$&*();:/|. ]*$/
		const value = event.target.value

		if (regExp.test(value)) {
			setInputValue(value)
		}
	}

	const handleDrop = event => {
		event.preventDefault()
		const droppedFiles = Array.from(event.dataTransfer.files)
		setFilesData(droppedFiles)
		setInputValue(droppedFiles)
	}

	const handleDragOver = event => {
		event.preventDefault()
	}

	switch (inputType) {
		case 'date':
			return (
				<input
					type='date'
					value={inputValue}
					max={maxValue || maxDate}
					min={minValue || undefined}
					onChange={e => setInputValue(e.target.value)}
					className={style.input}
				/>
			)

		case 'files':
			return (
				<label
					className={style.fileLabel}
					onDragOver={handleDragOver}
					onDrop={handleDrop}
				>
					<input
						type='file'
						multiple
						onChange={e => {
							setFilesData(Array.from(e.target.files))
							setInputValue(Array.from(e.target.files))
						}}
						hidden
					/>

					{filesData.length ? (
						<>
							<p>{filesData[0].name}</p>
							{filesData.length > 1 && (
								<p>
									+ {filesData.length - 1} {TEXT.files}
								</p>
							)}
						</>
					) : (
						<>
							<p>{TEXT.drag}</p>
							<p>{TEXT.click_and_select}</p>
						</>
					)}
				</label>
			)

		case 'file_one':
			return (
				<label
					className={style.fileLabel}
					onDragOver={handleDragOver}
					onDrop={handleDrop}
				>
					<input
						type='file'
						onChange={e => {
							const file = e.target.files[0]
							setFilesData(file ? [file] : [])
							setInputValue(file ? [file] : [])
						}}
						hidden
					/>

					{filesData.length ? (
						<p>{filesData[0]?.name}</p>
					) : (
						<>
							<p>{TEXT.drag}</p>
							<p>{TEXT.click_and_select}</p>
						</>
					)}
				</label>
			)

		case 'password':
			return (
				<input
					type='password'
					placeholder={inputPlaceholder}
					value={inputValue}
					onChange={handleCheckStringAndUpdate}
					className={style.input}
					onKeyDown={optionalHadle}
				/>
			)

		case 'radio':
			return (
				<input
					type='radio'
					value={inputValue}
					checked={inputValue === selectedValue}
					onChange={() => setInputValue(inputValue)}
					hidden
				/>
			)

		case 'number':
			return (
				<input
					type='number'
					min='0'
					placeholder={inputPlaceholder}
					value={inputValue}
					onChange={handleCheckNumberAndUpdate}
					className={style.input}
					// onKeyDown={optionalHadle}
				/>
			)

		default:
			return (
				<input
					type='text'
					placeholder={inputPlaceholder}
					value={inputValue}
					onChange={handleCheckStringAndUpdate}
					className={style.input}
					onKeyDown={optionalHadle}
				/>
			)
	}
}
