import React from 'react'
import style from './UploadProgressModal.module.scss'

export const UploadProgressModal = ({ progress }) => {
	return (
		<div className={style.uploader}>
			<div className={style.modal}>
				{progress.map(fileProgress => (
					<div key={fileProgress.fileName} className={style.row}>
						<div className={style.row__info}>
							<p>{fileProgress.fileName}</p>
							<p>{fileProgress.progress}%</p>
						</div>

						<div className={style.line}>
							<div
								style={{
									width: `${fileProgress.progress}%`,
								}}
								className={style.line__done}
							></div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
